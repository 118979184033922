body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar-menu {
  position: fixed !important;
  top: 0;
  bottom: 0;
}

.dropzone_main {
  background: #ffffff;
  border-radius: 5px;
  padding: 10px;
  border: 1px dashed #c3c3c3;
  position: relative;
  min-height: 180px;
  width: 100%;
  .dz_img_wrp {
    position: relative;
    display: inline-block;
    .remove_img {
      position: absolute;
      top: -7px;
      right: 0;
      color: red;
      cursor: pointer;
      z-index: 1;
    }
  }
}

.widget-dashboard {
  border-radius: 15px;
  padding: 10px 20px 15px 10px;
  color: black;
  h4 {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
  h1 {
    font-size: 30px;
    line-height: normal;
    font-weight: bold;
    margin: 0;
  }
  i {
    margin-left: 90%;
    font-weight: bold;
    font-size: 24px;
  }
}

.widget-dashboard:hover {
  color: white;
  background-color: #000;
}

.widget-bg-4 {
  background-color: #d4d7db;
}

.widget_main {
  padding: 0 0 10px 0;
}

@import '~admin-lte/dist/css/adminlte.min.css';
@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';

.main-footer {
  cursor: default;
  span {
    color: #30353a;
    text-decoration: none;
    background-color: transparent;
  }
}

.brand-link {
  border-bottom: none !important;
  box-shadow: none;
  background-color: #15191d;
  padding: 11px 10px;
  text-align: center;
  .brand-logo {
    line-height: 0.8;
    height: 35px;
  }
}
.nav-sidebar .nav-item {
  padding: 10px;
  .nav-link {
    padding: 1rem;
    border-radius: 15px;
    .nav-icon {
      margin-right: 8px;
      opacity: 0.5;
    }
  }
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active,
.sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active {
  background-color: #30353a;
  i {
    opacity: 1;
  }
}
.sidebar {
  padding: 0;
}

.fa-3x {
  font-size: 1em;
}

.breadcrumb-item.active {
  color: #343a40;
  font-weight: 600;
  font-size: 14px;
}
.breadcrumb-item a {
  color: #000;
  font-weight: 600;
}
.breadcrumb-item a:hover {
  color: #343a40;
}
.navbar-nav .nav-item a i {
  font-weight: 900;
  color: #7b8088;
}
.dropdown-toggle::after {
  color: #7b8088;
  position: absolute;
  top: 10px;
  right: 0;
}
.nav-link.dropdown-toggle {
  padding: 0;
  margin-right: 25px;
  display: inline;
  i {
    margin-right: 10px;
    border: 1px solid #343a40;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
  }
}

.navbar-expand .navbar-nav .dropdown-menu {
  border: 0;
  border-radius: 15px;
  overflow: hidden;
  .dropdown-item {
    color: #30353a;
    cursor: pointer;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    background-color: #f3f3f3;
  }
}

.card {
  box-shadow: none;
  border-radius: 15px;
  .dataTable-search input:focus-visible {
    outline: none;
  }
  .rdt_TableCell {
    min-width: 50px;
    max-width: 420px;
  }
  .rdt_TableCell:last-child {
    min-width: 120px;
  }
  .card-body .list-header {
    width: 80%;
    float: left;
  }
  .card-body .dataTable-search {
    width: 20%;
    float: left;
    margin: 11px 0;
    padding-left: 15px;
    position: relative;
    input {
      border-radius: 15px;
      border: 0;
      background-color: #f4f6f9;
      padding: 7px 15px;
      width: 100%;
    }
    img {
      position: absolute;
      right: 14px;
      top: 9px;
      width: 21px;
    }
  }
  .rdt_TableHeadRow,
  .rdt_TableRow {
    justify-content: space-between;
  }
  .rdt_TableRow .sc-hKgJUU button.btn {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    padding: 0;
  }
}

.btn-primary {
  background-color: #30353a;
  border-color: #30353a;
  border-radius: 10px;
  padding: 7px 20px;
}
.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:active {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-secondary {
  border-radius: 10px;
  padding: 7px 30px;
}
.btn-secondary:hover {
  background-color: #2f3234;
  border-color: #2f3234;
}
.iiHmzE {
  justify-content: space-between;
}
.content-wrapper {
  padding-top: 15px;
  form {
    background: #fff;
    margin: 15px;
    width: calc(100% - 40px);
    border-radius: 15px;
    margin-top: 0;
    padding-top: 0;
    max-width: 970px;
    .card-body {
      margin-top: 0;
      .form-control,
      .css-1s2u09g-control {
        background-color: #f4f6f9;
        border: 0;
        border-radius: 10px;
      }
      .css-1insrsq-control {
        border-radius: 10px;
      }
      .dropzone_main {
        border: 1px dashed #d4d7db;
        background-color: #f4f6f9;
      }
    }
    .card-footer {
      background-color: #fff;
      border-radius: 0 0 15px 15px;
    }
  }
}

.login-logo {
  margin-bottom: 30px;
  img {
    mix-blend-mode: darken;
    max-width: 120px;
  }
}
.login-screen {
  .row {
    margin-right: 0;
    margin-left: 0;
  }
  .card {
    border-radius: 10px;
    overflow: hidden;
  }
  .login-box {
    width: 410px;
    .login-box-msg {
      font-weight: 600;
      font-size: 20px;
      color: #30353a;
    }
  }
  .left-img-wrap {
    padding: 150px;
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
  }
}

.user-profile-wrap {
  width: calc(100% - 30px);
  background: #fff;
  border-radius: 15px;
  margin: 0 15px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  .user-img-wrap {
    max-width: 200px;
    img {
      width: 100%;
    }
  }
  .background-image {
    width: 100%;
    max-height: 350px;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 15px;
    img {
      width: 100%;
      height: 350px;
      object-fit: cover;
      object-position: center;
    }
  }
  .user-detail-wrap {
    width: calc(100% - 200px);
    padding-left: 40px;
    .user-name {
      display: flex;
      label {
        margin-right: 10px;
        color: #343a40;
      }
    }
    .user-phone {
      display: flex;
      label {
        margin-right: 10px;
        color: #343a40;
      }
    }
    .user-email {
      display: flex;
      label {
        margin-right: 10px;
        color: #343a40;
      }
    }
    .card-footer {
      padding: 0;
      background-color: #fff;
    }
  }
}

.store-detail-wrap {
  background: #fff;
  padding: 20px;
  width: calc(100% - 30px);
  margin: 0 15px;
  border-radius: 15px;
  .background-image .card {
    width: 100%;
    max-height: 350px;
    overflow: hidden;
    margin-bottom: 30px;
    img {
      width: 100%;
      height: 350px;
      object-fit: cover;
      object-position: center;
    }
  }
}

.store-detail-wrap .store-content {
  display: flex;
  .store-detail-content {
    padding-left: 45px;
    .store-name {
      display: flex;
      label {
        margin-right: 15px;
        color: #343a40;
      }
    }
    .store-desc {
      display: flex;
      label {
        margin-right: 15px;
        color: #343a40;
      }
    }
    .store-link {
      display: flex;
      label {
        margin-right: 15px;
        color: #343a40;
      }
      a {
        color: #343a40;
      }
      a:hover {
        color: #dc3545;
      }
    }
  }
  .card-footer {
    padding: 0;
    background-color: #fff;
  }
  .store-detail-content p {
    color: #545b62;
  }
}

//for loader
.loader {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  border: 3px solid;
  border-top-color: #fff;
  border-bottom-color: rgba(255, 255, 255, 0.3);
  border-left-color: #fff;
  border-right-color: rgba(255, 255, 255, 0.3);
  -webkit-animation: loader 1s ease-in-out infinite;
  animation: loader 1s ease-in-out infinite;
  vertical-align: sub;
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.table_default {
  width: 100%;
  min-height: 200px;
  .table_loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 10;
    border-radius: 5px;
    width: 100%;
    .loader {
      z-index: 11;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-top-color: #000000;
      border-left-color: #000000;
    }
  }
  .table_content {
    display: inline-block;
    width: 100%;
    margin: 10px;
    padding: 20px;
  }
}

.product-detail-wrap {
  background: #fff;
  padding: 20px;
  border-radius: 15px;
  width: calc(100% - 30px);
  margin: 0 15px;
  display: flex;
  flex-wrap: wrap;
  .product-images {
    width: 40%;
    .carousel {
      min-height: 480px;
    }
    .carousel-indicators li {
      background-color: #6c757d;
    }
    .carousel-control-prev {
      display: none;
    }
    .carousel-control-next {
      display: none;
    }

    .carousel-item img {
      height: auto;
      max-height: 600px;
      object-fit: cover;
      object-position: top;
    }
  }
  .product-content-wrap {
    width: 60%;
    padding-left: 35px;
    .product-name {
      display: flex;
      label {
        margin-right: 10px;
      }
    }
    .product-desc {
      display: flex;
      label {
        margin-right: 10px;
      }
    }
    .product-price {
      display: flex;
      label {
        margin-right: 10px;
      }
    }
    .product-material {
      display: flex;
      label {
        margin-right: 10px;
      }
    }
    .product-color {
      display: flex;
      label {
        margin-right: 10px;
      }
    }
    .product-size {
      display: flex;
      label {
        margin-right: 10px;
      }
    }
    .product-link {
      display: flex;
      label {
        margin-right: 10px;
      }
    }
    .card-footer {
      padding: 0;
      background-color: #fff;
    }
  }
}

/* 24-02-2022 PrivacyPolicy */
.policy-sec {
  position: relative;
  padding: 50px 0;

  .title {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 50px;
    label {
      font-size: 14px;
      font-weight: 500;
      color: #000;
      opacity: 0.5;
    }
  }
}

.policy-content {
  a {
    color: #000;
    font-weight: 400;
    text-decoration: underline;
  }
  p,
  li {
    font-size: 15px;
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: square;
  }

  h3 {
    margin: 35px 0 20px 0;
    font-weight: 700;
  }
  h5 {
    margin: 30px 0 15px 0;
  }
}

/* END */

@media (max-width: 1619px) {
  .login-screen .left-img-wrap {
    padding: 100px;
  }
}

@media (max-width: 1429px) {
  .card .gzjvyG {
    min-width: 50px;
    max-width: 370px;
  }
  .card .card-body .list-header {
    width: 70%;
  }
  .card .card-body .dataTable-search {
    width: 30%;
  }
}

/*Facebook Instruction*/
.facebook-instruction {
  p {
    font-size: 15px;
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 1rem;
  }
  ul {
    list-style: auto;
    margin-top: 10px;
    li {
      font-size: 15px;
      line-height: 30px;
    }
  }
  .thank-you {
    font-weight: 500;
    font-style: italic;
  }
}
